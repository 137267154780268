import { Button, Title } from '@kvdbil/components';
import React from 'react';
import styled from 'styled-components';
import Link from '~/App/shared/components/Link';
import Media from '~/App/shared/components/Media';
import { FeaturedCardRecord } from '~/config/generated/graphql';
import { DatoTitleTag } from '~/App/shared/interfaces/datoCMS';
import { isEmptyDocument } from 'datocms-structured-text-utils';
import StructuredText from '~/App/components/DatoCMS/StructuredText';
import { Color, Size } from '@kvdbil/components/types/Types';

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: ${({ theme }) => theme.colors.background.light};
`;

const StyledMedia = styled(Media)`
  width: 100%;
  display: block;
  height: 252px;
  object-fit: cover;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  flex: 1;
`;

const StructuredTextWrapper = styled.div`
  margin-top: -0.5rem;
  flex: 1;
`;

const StyledLink = styled(Link)`
  padding-top: 1rem;
`;

const LargeFeaturedCard = ({
  image,
  titleWithText,
  buttons
}: FeaturedCardRecord) => {
  const title = titleWithText?.[0]?.title?.[0];
  const text = titleWithText?.[0]?.text;
  const button = buttons?.[0];
  return (
    <StyledCard>
      <StyledMedia
        src={image?.url ?? ''}
        alt={image?.alt ?? ''}
        imageParams={{
          w: 500
        }}
        ratio={22 / 9}
      />
      <Content>
        <Title as={(title?.tag ?? 'h3') as DatoTitleTag}>{title?.text}</Title>
        {!isEmptyDocument(text) && (
          <StructuredTextWrapper>
            <StructuredText data={text} />
          </StructuredTextWrapper>
        )}

        {button?.text && button?.url && (
          <StyledLink to={button?.url}>
            <Button
              size={(button?.size ?? 'regular') as Size}
              color={(button?.color ?? 'secondary') as Color}
              fullWidth
            >
              {button?.text}
            </Button>
          </StyledLink>
        )}
      </Content>
    </StyledCard>
  );
};

export default LargeFeaturedCard;
